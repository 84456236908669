.thumbnail {
  width: 120px;
  height: 120px;
  object-fit: contain;
}

.title {
  width: 30%;
}

.memo {
  max-width: 25%;
}