body {
  font-size: 14px;
}

.form-control::placeholder {
  color: #cccccc;
}

textarea.form-control {
  height: 200px;
}