@import '/src/assets/scss/variables.scss';

.linkContainer {
  padding: 24px;
  width: 100%;
}

.linkText {
  color: $text;
  font-size: 16px;

  &:hover {
    color: #212529;
  }
}
