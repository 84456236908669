.suggestionBox {
  border: 1px solid #ced4da;
  margin-top: 12px;
  max-height: 200px;
  overflow: scroll;
}

.suggestion {
  border-bottom: 1px solid #ced4da;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  height: 48px;

  &:hover {
    background-color: #ced4da;
    cursor: pointer;
  }

  &:last-child {
    border-bottom: none;
  }
}

.tipBox {
  display: flex;
  flex-wrap: wrap;
  margin: unset;
}

.tip {
  border: solid 1px #ced4da;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 6px;
  margin-top: 6px;
  margin-right: 6px;
}

.removeBtn {
  display: block;
  position: relative;
  margin-left: 6px;
  height: 100%;
  width: 14px;

  @mixin border($deg) {
    content: '';
    display: block;
    background: gray;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 0px;
    right: 0px;
    transform: translateY(-50%) rotate($deg);
    height: 12px;
    width: 3px;
  }

  &:after {
    @include border(-45deg);
  }

  &:before {
   @include border(45deg);
  }
}