@import '/src/assets/scss/variables.scss';

.header {
  box-sizing: border-box;
  border-bottom: solid 1px $bar;
  height: 60px;
}

.main {
  box-sizing: border-box;
  padding: 0px;
}

.sidebar {
  border-right: solid 1px $bar;
  box-sizing: border-box;
  height: calc(100vh - 60px);
}

.content {
  background-color: #f9fafb;
  padding: 24px;
  overflow: scroll;
  height: calc(100vh - 60px);
}
